<script>
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const { params, query } = useRoute();
    const { path } = params;
    useRouter().replace({ path: "/" + path, query });
  },
  render: function (h) {
    return h(); // avoid warning message
  },
};
</script>
